import { Avatar, Box, Stack, Text, useColorModeValue } from '@chakra-ui/react'

export default function WithLargeQuote() {
  return (
    <Stack
      py={8}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={'center'}
      direction={'column'}>
        <Box>
          <Text fontSize={{ base: 'xl', md: '2xl' }} textAlign={'center'} maxW={'3xl'}>
            “Harness the power of quantum computing to generate genuine random bits. Witness the future of unpredictability and security, see the true randomness.”
          </Text>
        </Box>

    </Stack>
  )
}
