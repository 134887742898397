import {
  Heading,
  Box,
  Center,
  Image,
  Text,
  Stack,
  Button,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react';
import axios from 'axios';



// TerminalBox Component
const TerminalBox = ({ bits }) => {
  const [displayedBits, setDisplayedBits] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setDisplayedBits(''); // Clear displayedBits when bits change
    setCurrentIndex(0); // Reset currentIndex when bits change
  }, [bits]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < bits.length) {
        setDisplayedBits((prevBits) => prevBits + bits[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust the interval time as needed

    return () => clearInterval(interval);
  }, [bits, currentIndex]);

  return (
    <Box
    w={'350px'}
    h={'350px'}
      style={{
        overflowY: 'auto',
        backgroundColor: 'black',
        color: 'white',
        fontFamily: 'monospace',
        padding: '10px',
        whiteSpace: 'pre-wrap'
      }}
    >
      {displayedBits}
    </Box>
  );
};



// Below is work
export default function SocialProfileWithImage() {


  const [count, setCount] = useState(null);
  const [remain, setRemain] = useState(null);


  const fetchData = async () => {
    try {
      // Make HTTP GET request using Axios
      const response = await axios.get('https://www.web-randombits.com/api/count');
      // Update state with response data (assuming the response is an object with a "count" property)
      // response.data[0] == not used
      // response.data[1] == used

      setCount((response.data[0] + response.data[1]));
      setRemain(response.data[0]);

    } catch (error) {
      // Handle errors
      console.log(error);
    }
  };


  // Fetch data initially when component mounts
  useEffect(() => {
    fetchData();

    // Set up interval to fetch data every 10 seconds
    const interval = setInterval(fetchData, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures the effect runs only once





  const toast = useToast();
  const toastSuccessId = 'success-toast';
  const toastFailId = 'fail-toast';

  const successToast = () => {

    toast({
      id: toastSuccessId,
      title: "Generated Successfully",
      status: "success", // Can be "success", "error", "warning", "info"
      position: "top",
      duration: 5000, // Duration in milliseconds
      isClosable: true,
    });

  }
  const failToast = () => {

    toast({
      id: toastFailId,
      title: "Reached Max",
      status: "info", // Can be "success", "error", "warning", "info"
      description: "you've reached the max generated, try again after a few hour.",
      position: "top",
      duration: 5000, // Duration in milliseconds
      isClosable: true,
    });

  }

  useEffect(() => {
    firstTime(); // Fetch data when the component mounts
  }, []);

  const [bits, setBits] = useState(null);
  const [imageBlack, setImageBlack] = useState(null);
  const [imageColored, setImageColored] = useState(null);
  const [loading, setLoading] = useState(true);

  const firstTime = async () => {

    try {

      fetchData();

      if(remain !== 0) {

        // Make HTTP GET request using Axios
        const response = await axios.get('https://www.web-randombits.com/api/bits');
        // Update state with response data (assuming the response is an object with a "count" property)
        setBits(response.data[1]);
        setImageBlack(response.data[2]);
        setImageColored(response.data[3]);

        fetchData();

      } 
      
    } catch (error) {
      // Handle errors
      console.log(error);
      setLoading(false); // Set loading to false when data is fetched
    }

  };

  const handleClick = async () => {

    try {

      fetchData();

      if(remain !== 0) {

        // Make HTTP GET request using Axios
        const response = await axios.get('https://www.web-randombits.com/api/bits');
        // Update state with response data (assuming the response is an object with a "count" property)
        setBits(response.data[1]);
        setImageBlack(response.data[2]);
        setImageColored(response.data[3]);

        fetchData();

        setLoading(false); // Set loading to false when data is fetched


        if (!toast.isActive(toastSuccessId)) {
          successToast();
        }

      } else {

        if (!toast.isActive(toastFailId)) {
          failToast();
        }
      }
      
    } catch (error) {
      // Handle errors
      console.log(error);
      setLoading(false); // Set loading to false when data is fetched
    }

  };
  const temporaryBits = "101001010100101001010110010101010010101010101010010101010100101001010101010101101010010101010010101010010101010101010010101010100101010010100101011001010101001010101010101001010101010010100101010101010110101001010101001010101001010101010101001010101010010100101010101010110101001010101001010101011010100101010100101010101101010010101010100101001010101010101101010010101010010101010110101001010101001010101011010100101010";

  return (
    <Center py={4} flexDirection={{ base: 'column', md: 'row' }} flexWrap={'wrap'}>


      <Box
        maxW={'350px'}
        w={{ base: '90%', md: '45%' }}
        
        margin={{ base: '20px 0', md: '40px' }}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'xl'}
        overflow={'hidden'}>
        <Image
          maxH={'350px'}
          H={'350px'}
          w={'350px'}
          src={imageBlack ? 'data:image/jpeg;base64,' + imageBlack :
            'https://static.vecteezy.com/system/resources/previews/002/298/031/non_2x/binary-pattern-black-vector.jpg'}

          objectFit="cover"
          alt="#"
        />

        <Box py={10} paddingBottom={0}>
          <Stack spacing={0} align={'center'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
              Black and White Image
            </Heading>
            <Text py={8} textAlign={'center'} color={'gray.500'}>Quantum computing generated <br/> black and white image <br/> (190x190)</Text>
          </Stack>
        </Box>
      </Box>


      <Box
        maxW={'350px'}
        w={{ base: '90%', md: '45%' }}
        h={'574.8px'}
        margin={{ base: '20px 0', md: '40px' }}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'xl'}
        overflow={'hidden'}
        >

        <TerminalBox bits={bits ? bits.match(/.{1,5000}/g) : temporaryBits.match(/.{1,1}/g)}/>    

        <Box py={10} paddingBottom={0}>
          <Stack spacing={0} align={'center'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
              Sequence of Random Bits
            </Heading>
            <Text py={8} textAlign={'center'} color={'gray.500'}>Quantum computing generated sequence <br/> 37800 bits</Text>
          </Stack>
        </Box>
      </Box>


      <Box
        maxW={'350px'}
        w={{ base: '90%', md: '45%' }}
        margin={{ base: '20px 0', md: '40px' }}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'xl'}
        overflow={'hidden'}>

        <Image
          maxH={'350px'}
          H={'350px'}
          w={'350px'}
          src={imageColored ? 'data:image/jpeg;base64,' + imageColored :
            'https://static.vecteezy.com/system/resources/previews/002/298/031/non_2x/binary-pattern-black-vector.jpg'}
          objectFit="cover"
          alt="#"
        />

        <Box py={10} paddingBottom={0}>
          <Stack spacing={0} align={'center'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
              Colored Image
            </Heading>
            <Text py={8} textAlign={'center'} color={'gray.500'}>Quantum computing generated <br/> colored image <br/> (156x156)</Text>
          </Stack>
        </Box>
      </Box>


      

      <Stack spacing={0} align={'center'} mb={5} w={'full'}>

        <Button
                w={{ base: '90%', md: '45%' }}
                mt={6}
                bg={useColorModeValue('#151f21', 'gray.900')}
                color={'white'}
                rounded={'md'}
                onClick={handleClick} 
                disabled={loading}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}>
                Click the button to generate new random bits
        </Button>



          <Text
                  w={{ base: '20%', md: '10%' }}
                  py={5}
                  bg={'white'}
                  textAlign={'center'}
                  fontSize={{ base: 'xs', md: 'xs' }}
                  color={'grey'}
                  rounded={'md'}
                  >
                  Total: {count}
                  <br/>
                  Remain: {remain}
          </Text>

      </Stack>
      
    </Center>
  )
}
