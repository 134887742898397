
import { Box, Image } from '@chakra-ui/react'

import bg from "../images/bg-image.png";
import source from "../images/wavesNegative.svg";

export default function Nav() {
  return (

    <Box position={'relative'}>
      <Image src={bg} width='100%'/>
      <Image src={source} w={'100%'} style={{ position: 'absolute', bottom: 0, left: 0 }} />
    </Box>
      
  )
}