import React from 'react';
import {
  ChakraProvider,
  Text,
  Stack,
  theme,
  Image,
} from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Card from './components/Card';
import Quote from './components/Quote';
import Footer from './components/Footer';
import qubitlogo from './images/random-bits2.png';


function App() {
  

  return (
    <ChakraProvider theme={theme}>
      <Navbar/>
      <Stack
        py={8}
        px={8}
        spacing={{ base: 8, md: 10 }}
        align={'center'}
        direction={'column'}>
        <Image src={qubitlogo} alt="Logo" w={'550px'} h={'full'} />
        <Text py={10} fontSize={{ base: 'xl', md: '2xl' }} textAlign={'center'} maxW={'3xl'}>
          Quantum computing leverages quantum phenomena like superposition and entanglement to process data 
          exponentially faster than classical computers, promising revolutionary breakthroughs.
        </Text>
      </Stack>
      <Card/>
      <Quote/>
      <Footer/>
    </ChakraProvider>
  );
}

export default App;
